$(function() {
	// User session has an active subscription
	if ( JSON.parse(KETbilietas.subscription) ) {
		// console.log(JSON.parse(KETbilietas.subscription))
		var sub = JSON.parse(KETbilietas.subscription).remaining
		var interval;
		function showSubscriptionTime() {
			// console.log('show sub')
			$('.nav.kodas .time.full').formatTime(sub, { leading: false, seconds: true });
		}
		showSubscriptionTime();
		// Subscription time countdown
		setInterval(function() {
			// console.log('refreshing')
			sub -= 1;
			showSubscriptionTime();
			if ( sub < 0 ) {
				clearInterval(interval);
			}
		}, 1000);
	}
});

$.fn.formatTime = function(time, options) {
	// Format options
	options = _.defaults({}, options, {
		seconds: true,
		leading: true,
		empty: 'Jūsų laikas baigėsi'
	});
	// Empty container
	$(this).empty();
	// Hours
	if ( time > 60*60 ) {
		var hours = Math.floor(time/(60*60));
		if ( options.leading && hours < 10 ) hours = '0' + hours;
		$('<span>').addClass('hours').text(hours).appendTo(this);
		time -= hours*60*60;
	}
	// Minutes
	if ( time > 60 ) {
		var mins = Math.floor(time/60);
		if ( options.leading && mins < 10 ) mins = '0' + mins;
		$('<span>').addClass('minutes').text(mins).appendTo(this);
		time -= mins*60;
	}
	// Seconds
	if (options.seconds) {
		if ( time >= 0 ) {
			if ( options.leading && time < 10 ) time = '0' + time;
			$('<span>').addClass('seconds').text(time).appendTo(this);
		} else {
			// Negative value, means no time left
			$('<span>').addClass('out').text(options.empty).appendTo(this);
		}
	}
	
    return this;
}

$('document').ready(function() {
    $('#testInput').on('blur', function() {
        if ($(this)[0].value === '') {
            $('#testArrow').prop("disabled", true);
        } else {
            $('#testArrow').prop("disabled", false);
        }
    });
});
